<template>
  <div class="base-datepicker d-flex m-cursor-pointer" @click="$bvModal.show(modalId)">
    <DateIcon v-if="icon === 'Date'" class="mr-3 mt-2" />
    <TimeIcon v-if="icon === 'Time'" class="mr-3 mt-2" />
    <p class="mb-0">
      <span class="d-block mb-1">{{ icon }}</span>
      <span class="base-datepicker__selected d-block">{{ selectedDate }}</span>
    </p>
    <primary-modal :modal-id="modalId" :title="title">
      <div class="skill-schedule">
        <DatePicker
          v-model="pickedDate"
          :type="type"
          :format="format"
          inline
          :show-time-header="showTimeHeader"
          :time-title-format="timeTitleFormat"
          prefix-class="xmx"
          :disabled-date="disabledDate"
          :default-value="defaultValue"
          @input="handleInput"
        />
      </div>
    </primary-modal>
  </div>
</template>
<script>
import DateIcon from "@/components/svg/DateIcon";
import TimeIcon from "@/components/svg/TimeIcon";
import DatePicker from "vue2-datepicker";
import "@/assets/scss/datepicker.scss";
import PrimaryModal from "./PrimaryModal.vue";

export default {
  name: "BaseDatePicker",
  components: {
    DateIcon,
    TimeIcon,
    DatePicker,
    PrimaryModal,
  },
  props: {
    type: {
      type: String,
      default: "date",
      validator(value) {
        return ["date", "datetime", "time", "week", "year", "month"].includes(value);
      },
    },
    selected: {
      type: String,
      default: "Please Select",
    },
    disabledDate: {
      type: Function,
      default: () => {
        return false;
      },
    },
    defaultValue: {
      type: Date,
      default: () => new Date(),
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
    value: {
      type: Date,
      default: null,
    },
    showTimeHeader: {
      type: Boolean,
      default: true,
    },
    timeTitleFormat: {
      type: String,
      default: "YYYY-MM-DD",
    },
  },
  data() {
    return {
      selectedDate: this.selected,
      pickedDate: null,
      modalId: "date_modal" + Math.floor(Math.random() * 3000),
    };
  },
  computed: {
    icon() {
      let format = this.format.toLowerCase();
      return format.includes("yyyy") || format.includes("yy") || format.includes("y")
        ? "Date"
        : "Time";
    },
    title() {
      return `Pick ${this.icon}`;
    },
  },
  methods: {
    handleInput() {
      if (this.pickedDate instanceof Date && this.icon === "Date") {
        this.selectedDate = this.pickedDate.toLocaleDateString("en-us", {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      }

      if (this.pickedDate instanceof Date && this.icon === "Time") {
        this.selectedDate = this.pickedDate.toLocaleTimeString("en-us");
      }
      this.$bvModal.hide(this.modalId);
      this.$emit("input", this.pickedDate);
    },
  },
};
</script>
<style lang="scss" scoped>
.base-datepicker {
  border-radius: $size-10;
  padding: $size-10;
  border: 1px solid $grey-tint-5;
  &__selected {
    font-weight: bold;
    font-size: 1rem;
  }
}
</style>
