<template>
  <b-modal
    id="skill_modal"
    modal-class="skill-modal"
    hide-footer
    hide-header
    @hidden="hidden"
  >
    <div class="pb-5">
      <img
        v-if="skill.class === null"
        src="../../assets/img/skill-icon.png"
        class="img-fluid"
      />
      <i
        v-if="skill.class !== null"
        class="skill-modal__icon d-flex justify-content-center m-fs-30 my-4 colored"
        :class="skill.class"
      >
      </i>
      <h3 class="skill-modal__title d-flex justify-content-center mt-2">
        {{ skill.name }} Skill Assessment
      </h3>
      <div class="">
        <ProgressTracker :stages="stages" />
      </div>
      <h2 class="skill-modal__briefTitle d-flex justify-content-center mt-4">
        Brief about the skill
      </h2>
      <p
        class="skill-modal__briefContent d-flex justify-content-center text-center mt-4 mx-3"
      >
        {{ skill.description || description }}
      </p>

      <div class="px-5">
        <b-form-select
          v-model="selected"
          :options="options"
          size="sm"
          class="skill-modal__select my-4 d-flex justify-content-center"
        ></b-form-select>
      </div>

      <div class="d-flex justify-content-center">
        <b-button
          block
          class="text-decoration-none btn-primary mt-3 width-200"
          variant="primary"
          :disabled="!selected"
          @click="promptAssessment()"
        >
          {{ isBusy ? "Starting..." : btnText }}
        </b-button>
      </div>
    </div>
    <prompt-modal
      modal-id="skill_prompt"
      ok-title="Continue"
      cancel-title="Cancel"
      title="Caution!"
      @ok="startAssessment()"
    >
      <p class="m-fs-16 text-center">
        Starting this test means that you must finish it, and you will not be
        able to start the test over again unless you fail and a new date is
        scheduled for the retake.
      </p>
    </prompt-modal>
  </b-modal>
</template>

<script>
import ProgressTracker from "./ProgressTracker.vue";
import PromptModal from "../PromptModal.vue";

export default {
  name: "SkillModal",
  components: {
    ProgressTracker,
    PromptModal,
  },
  props: {
    skill: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
      options: [
        {
          value: null,
          text: "Select your years of experience in the skills above",
        },
        { value: "intern", text: "Intern <0 year>" },
        { value: "junior", text: "Junior <1-2 years>" },
        { value: "intermediate", text: "Intermediate <3-5 years>" },
        { value: "senior", text: "Senior <5+ years>" },
      ],
      assessments: require("@/data/assessments.json"),
    };
  },
  computed: {
    description() {
      return `This is the ${
        this.skill.name
      } skill assessments, it consists of the following assessments; ${this.skill.assessments.join(
        ", "
      )}.`;
    },
    stages() {
      return this.skill.assessments.map((assessment) => {
        return {
          id: assessment,
          title: this.assessments[assessment],
          description: "",
          status: this.skill[`${assessment}_ongoing`] ? "completed" : "pending",
        };
      });
    },
    btnText() {
      if (this.skill.assessments.length < 2) return "Schedule Interview";

      return "Start Assessment";
    },
  },
  methods: {
    promptAssessment() {
      if (this.skill.assessments.length > 1) {
        this.$bvModal.show("skill_prompt");
      } else {
        this.startAssessment();
      }
    },
    startAssessment() {
      this.$emit("started", this.selected);
    },
    hidden() {
      this.$emit("hidden");
    },
  },
};
</script>

<style lang="scss" scoped>
.skill-modal {
  &__title {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }

  &__briefTitle {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }

  &__briefContent {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }

  &__button {
    background: $deep-blue;

    @include media-breakpoint-down(sm) {
      width: 75% !important;
    }
  }
}
</style>
