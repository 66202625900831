<template>
  <div class="progress-tracker px-sm-5 mt-4">
    <div v-if="stages.length >= 2" class="progress-tracker__line"></div>
    <div class="d-flex flex-wrap justify-content-around">
      <div v-for="stage in stages" :key="stage.id" class="d-flex flex-column">
        <div
          class="progress-tracker__circle d-flex justify-content-center align-items-center"
          :class="{
            active: stage.status === 'pending',
            completed: stage.status === 'completed',
          }"
        >
          <span class="material-symbols-outlined completed-icon"> done </span>
        </div>
        <div class="text-center mt-2">
          <h6 class="progress-tracker__title">{{ stage.title }}</h6>
          <p class="progress-tracker__desc">{{ stage.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgressTracker",
  props: {
    stages: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    console.log(this.stages[0]);
    console.log(this.stages.length);
  },
};
</script>
<style lang="scss" scoped>
.progress-tracker {
  position: relative;
  .none {
    display: none;
  }

  &__line {
    position: absolute;
    left: 118px;
    top: 20%;
    width: 70%;
    height: 3px;
    background-color: #dfe4e9;
    z-index: 1;

    @include media-breakpoint-up(xl) {
      left: 145px;
      top: 20%;
      width: 70%;
    }

    @include media-breakpoint-down(md) {
      left: 120px;
      top: 20%;
      width: 70%;
    }

    @include media-breakpoint-down(sm) {
      left: 100px;
    }
    @media (min-width: 416px) and (max-width: 575px) {
      left: 70px;
      width: 75%;
    }
    @media (min-width: 275px) and (max-width: 415px) {
      left: 45px;
      width: 80%;
    }
    @media (min-width: 0px) and (max-width: 275px) {
      display: none;
    }
  }

  &__circle {
    width: 25px;
    height: 25px;
    border: 2px solid #dfe4e9;
    background-color: $white;
    border-radius: 50%;
    align-self: center;
    z-index: 9999;
    position: relative;

    .material-symbols-outlined {
      display: none;
      position: absolute;
      top: -1px;
      left: -1px;
      color: $white;
      font-size: 1rem;
    }

    &.active {
      border: 2px solid $deep-blue;
      position: relative;
      background-color: $white;

      &::before {
        content: "";
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: $deep-blue;
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }

    &.completed {
      background-color: $slightly-deep-green;
      position: relative;

      .material-symbols-outlined {
        &.completed-icon {
          display: block;
          font-size: 1.4rem;
        }
      }
    }
  }

  &__title {
    color: #1e3b59;
    font-size: $medium-size;
    font-weight: 800;
  }

  &__desc {
    font-size: $medium-size;
  }
}
</style>
