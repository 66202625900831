<template>
  <primary-modal modal-id="skill_schedule_modal" title="Schedule Interview">
    <div class="skill-schedule">
      <p>
        Please select the date and time you would love to have an interview on the
        {{ skill.category.name.toLowerCase() }}, <strong>{{ skill.name }} </strong>.
      </p>
      <BaseDatePicker v-model="form.interviewDate" class="mb-3" :disabled-date="disabledDate" />
      <BaseDatePicker v-model="form.interviewTime" :default-value="form.interviewDate" time-title-format="HH:mm" class="mb-3" type="time" format="HH" />
      <b-button
        block
        class="text-decoration-none btn-primary"
        :disabled="disableBtn"
        variant="primary"
        @click="schedule"
      >
        {{ isBusy ? "Scheduling..." : "Schedule" }}
      </b-button>
    </div>
  </primary-modal>
</template>
<script>
import PrimaryModal from "../PrimaryModal.vue";
import BaseDatePicker from "../BaseDatePicker.vue";
export default {
  name: "SkillScheduleModal",
  components: {
    PrimaryModal,
    BaseDatePicker,
  },
  props: {
    skill: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        interViewDate: null,
        interviewTime: null
      },
      processing: this.isBusy
    };
  },
  computed: {
    disableBtn() {
      return this.form.interviewDate == null || this.form.interviewTime == null
    }
  },
  methods: {
    disabledDate(date) {
      let targetDate = new Date();
      return (
        date < targetDate.setDate(targetDate.getDate() + 9) ||
        date > targetDate.setDate(targetDate.getDate() + 48)
      );
    },
    schedule() {
      this.$emit("scheduled", this.form);
      this.$bvModal.hide("skill_schedule_modal");
    },
  },
};
</script>
