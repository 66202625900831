export default {
  methods: {
    setQueryParam(params) {
      if (JSON.stringify(params) !== JSON.stringify(this.$route.query)) {
        this.$router.push({ path: this.$route.path, query: params });
      }
    },
    triggerActive() {
      //should be implemented in component
    },
    unsetActive() {
        let query = Object.assign({}, this.$route.query);
        delete query.active_id
        this.$router.replace({ query });
    },
  },
  created() {
    if (this.$route.query.active_id !== undefined) {
      this.triggerActive();
    }
  },
};
