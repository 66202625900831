<template>
  <div>
    <b-tabs v-model="tabIndex" card>
      <b-tab
        v-for="(tab, i) in tabs"
        :key="i"
        :title="tab.name"
        :active="i == tabIndex"
      >
        <div class="d-flex flex-wrap">
          <SkillCard
            v-for="(filter, j) in filterSkills(tab.id)"
            :key="j"
            :skill="filter"
            class="mb-2 mr-1 mr-md-2 m-cursor-pointer animate__animated animate__fadeInLeft"
            @click.native="selected(filter)"
          />
          <p v-if="filterSkills(tab.id) < 1">There is no skill available.</p>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SkillCard from "../../components/skills/SkillCard.vue";

export default {
  components: {
    SkillCard,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    skills: {
      type: Array,
      default: () => [],
    },
    searchTerm: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    selected(skill) {
      this.$emit("selected", skill);
    },
    filterSkills(id) {
      let skills = this.skills.filter((skill, i) => {
        return (
          skill.tags.includes(id) &&
          (this.searchTerm === "" ||
            skill.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
        );
      });

      if (this.searchTerm === "" && skills.length > 20) {
        skills.splice(27);
        return skills;
      }
      return skills;
    },
  },
};
</script>
<style lang="scss" scoped>
.card-body {
  @include media-breakpoint-down(sm) {
    padding: 0.5rem;
  }
}
</style>
